<template>
  <div>
    <base-template
      :description="$t('userCenter.noAccountText')"
      :empty-image="emptyImage"
      :is-empty="isEmpty"
    >
      <div class="main-box">
        <ul class="list-box">
          <li v-for="(item,index) in dataList" :key="index" class="list-item">
            <div class="item-top">
              <div class="idordate">
                <span>{{ item.IfyakushitaID }}</span>
                <span>{{ item.Ifyakushita_ipya }}</span>
              </div>
            </div>

            <div class="item-bottom">
              <ul class="item-list-box">
                <li v-for="(items,indexs) in itemList" :key="indexs" :class="{'price':items.key==='price'}" class="item-list-item">
                  <span class="title">{{ items.name }}</span>
                  <span class="cont">{{ items.key==='shitaIIfyakuD'?'PHP ':'' }} {{ items.key === 'UkucinjaKwakulekelesha'?repaymentType(item[items.key]):item[items.key] }} </span>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </base-template>
  </div>
</template>

<script>
import { getbillList } from '@/api/axios'
export default {
  data() {
    return {
      dataList: [{}],
      isEmpty: false,
      itemList: [
        { name: this.$t('userCenter.payAmount'), key: 'shitaIIfyakuD' },
        { name: this.$t('userCenter.paymentDate'), key: 'efwaiicilkwa' },
        { name: this.$t('userCenter.paymentMethod'), key: 'UkucinjaKwakulekelesha' }
        // { name: this.$t('userCenter.loanTimer'), key: 'pay_period' }
      ],
      emptyImage: require('@/assets/images/userCenter/no-account-icon.png')
    }
  },
  computed: {
    repaymentType() {
      return function(type, key) {
        return type + '' === '1' ? this.$t('userCenter.allPay') : this.$t('userCenter.extensionpay')
      }
    }
  },
  created() {
    this.getbillList()
  },
  methods: {
    async getbillList() {
      try {
        const { data } = await getbillList() || {}
        this.dataList = data || []
        this.isEmpty = !this.dataList.length
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.main-box{
  padding-top: 40px;
  .list-box{
    .list-item{
      // height: 366px;
      background: #FFFFFF;
      border-radius: 24px;
      border: 2px solid #CCCCCC;
      padding: 40px;
      padding-top: 24px;
      margin-bottom: 24px;
      .item-top{
        height: 52px;
        line-height: 52px;
        margin-bottom: 24px;
        .idordate{
          display: flex;
          justify-content: space-between;
          font-size: 36px;
          font-weight: bold;
          color: #000000;
          span:last-child{
            font-size: 28px;
            color: #45BE60;
          }
        }
      }
     .item-bottom{
        .item-list-item{
          height: 40px;
          font-size: 28px;
          color: #CCCCCC;
          line-height: 40px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
          .cont{
            font-size: 28px;
            color: #000000;
          }
        }
        .item-list-item.price{
          margin-bottom: 0;
          margin-top: 4px;
          .cont{
            font-size: 40px;
            font-weight: bold;
            color: #005FE9;
          }
        }
      }
    }
  }
:deep(.list-item-item){
  height:auto;
  line-height: 1;
  padding-top: 16px;
}
:deep(.list-item-item .cont){
  top: auto;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #002424;
}

:deep(.order-id){
  margin-bottom: 8px;
}
}
:deep(.el-empty__image img){
  width: 322px;
  height: 322px;
}

:deep(.empty__description){
  font-size: 32px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #858597;
  line-height: 38px;
  margin-top: 0;
}

:deep(.empty__description){
  font-size: 26px;
  color: #CCCCCC;
  line-height: 36px;
}

:deep(.el-empty__image img){
  width: 320px;
  height: 300px;
}
</style>
